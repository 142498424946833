<template>
  <div>
    <div class="d-md-none">
      <SideWidgetMobile />
    </div>
    <div class="d-none d-md-flex">
      <SideWidget />
    </div>
    <CreerCompteHeader />
    <CreerCompteForm />
  </div>
</template>

<script>
import SideWidgetMobile from '@/components/Layout/SideWidgetMobile.vue'
import SideWidget from '@/components/Layout/SideWidget.vue'
import CreerCompteHeader from '@/components/EspacePerso/CreerCompteHeader.vue'
import CreerCompteForm from '@/components/EspacePerso/CreerCompteForm.vue'

export default {
  name: 'ViewCreerCompte',
  components: {
    SideWidgetMobile,
    SideWidget,
    CreerCompteHeader,
    CreerCompteForm
  },
  props: {
    partnerId: { type: String }
  }
}
</script>
