<template>
  <div id="nestle-home-steps">
    <!-- mobile -->
    <form class="d-md-none nestlesteps-content" @submit.prevent.stop="submit">
      <agile
        :center-mode="true"
        :speed="1000"
        :dots="false"
      >
        <div class="slide">
          <div class="ccm-item">
            <div class="ccm-content">
              <div class="ccm-number">
                1.
              </div>
              <div class="ccm-text">
                {{ $t('nestleHomeSteps.step1') }}
              </div>
              <div class="ccm-email-block">
                <input
                  v-model="$v.email.$model"
                  type="text"
                  :placeholder="$t('user.email')"
                  :disabled="!!userEmail"
                  name="email"
                >
              </div>
              <div class="step1-msg">
                {{ $t('nestleHomeSteps.step1-msg') }}
              </div>
            </div>
          </div>
        </div>
        <div class="slide">
          <div class="ccm-item">
            <div class="ccm-content">
              <div class="ccm-number">
                2.
              </div>
              <div class="ccm-text mb-3">
                {{ $t('nestleHomeSteps.step2') }}
              </div>
              <div class="selectdiv">
                <select v-model="$v.hubId.$model" :placeholder="$t('article.portionWeight')">
                  <option v-for="hub in hubs" :key="hub.id" :value="hub.id">{{ hub.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="slide">
          <div class="ccm-item col col-lg-4">
            <div class="ccm-content">
              <div class="ccm-number">
                3.
              </div>
              <div class="ccm-text">
                {{ $t('nestleHomeSteps.step3') }}
              </div>
              <Button
                color="red"
                style="height:50px"
                :disabled="disabled"
                :text="$t('nestleHomeSteps.step3Button')"
                type="submit"
              />
            </div>
          </div>
        </div>
        <template slot="prevButton">
          <div class="prev">
            <div class="arrow" />
          </div>
        </template>
        <template slot="nextButton">
          <div class="next">
            <div class="arrow" />
          </div>
        </template>
      </agile>
    </form>

    <!-- desktop -->
    <form class="ccm-items row d-none d-md-flex" @submit.stop.prevent="submit">
      <div class="ccm-item col-6 col-lg-4">
        <div class="nestlesteps-content">
          <div>
            <div class="ccm-number">
              1.
            </div>
            <div class="ccm-text">
              {{ $t('nestleHomeSteps.step1') }}
            </div>
          </div>
          <div class="ccm-email-block">
            <input
              v-model="$v.email.$model"
              type="text"
              :placeholder="$t('user.email')"
              :disabled="!!userEmail"
              name="email"
            >
          </div>
          <div class="step1-msg">
            {{ $t('nestleHomeSteps.step1-msg') }}
          </div>
        </div>
      </div>
      <div class="ccm-item col-6 col-lg-4">
        <div class="nestlesteps-content">
          <div>
            <div class="ccm-number">
              2.
            </div>
            <div class="ccm-text">
              {{ $t('nestleHomeSteps.step2') }}
            </div>
          </div>
          <div class="selectdiv">
            <select v-model="$v.hubId.$model" :placeholder="$t('article.portionWeight')">
              <option v-for="hub in hubs" :key="hub.id" :value="hub.id">{{ hub.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="ccm-item col-6 col-lg-4">
        <div class="nestlesteps-content">
          <div>
            <div class="ccm-number">
              3.
            </div>
            <div class="ccm-text">
              {{ $t('nestleHomeSteps.step3') }}
            </div>
          </div>
          <Button
            color="red"
            style="height:50px"
            :disabled="disabled"
            :text="$t('nestleHomeSteps.step3Button')"
            type="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
import Button from '@/components/UI/Button.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'NestleHomeSteps',

  components: {
    agile: VueAgile,
    Button
  },

  data: () => ({
    loading: false,
    email: null,
    hubId: null,
    hubs: [],
    validDomains: []
  }),

  validations: {
    email: {
      required,
      email,
      domain(value) {
        if (!value) {
          return true
        }
        const domain = value.indexOf('@') > 0 && value.slice(value.indexOf('@') + 1)
        return this.validDomains.includes(domain)
      }
    },
    hubId: { required }
  },

  computed: {
    userEmail() {
      return this.$store.getters['auth/user']?.email
    },

    disabled() {
      return this.loading || this.$v.$invalid
    },

    lang() {
      return this.$store.getters['lang/current']
    }
  },

  watch: {
    'userEmail'(email) {
      this.email = email
    }
  },

  mounted() {
    this.email = this.userEmail
    return this.loadHubs()
  },

  methods: {
    loadHubs() {
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('hub/searchByTag', { tag: 'nestle' }),
          this.$store.dispatch('promo/getPromocode', { code: this.$config.nestleCode })
        ])
        .then(res => {
          this.hubs = res[0]
          this.validDomains = res[1]?.domains || []
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    async submit() {
      if (this.disabled) {
        return
      }
      this.loading = true

      // set preferences in session (ephemere) store
      this.$store.commit('user/preferences', {
        email: this.email,
        hubId: this.hubId
      })
      this.$store.commit('cart/email', this.email)

      // redirect to hub's linked shop
      const hub = this.hubs.find(hub => hub.id === this.hubId)
      const partnerId = hub.partners[0]

      await this.$store
        .dispatch('user/tempAccount', {
          email: this.email,
          lang: this.lang,
          partnerId
        })
        .catch(err => this.$err(err))

      // const route = this.$router.resolve({ name: 'shop', params: { partnerId, shopSlug: hub.firstShop.slug || hub.firstShop.id } })
      // window.location.href = route.href
      this.$router.push({ name: 'shop', params: { partnerId, shopSlug: hub.firstShop.slug || hub.firstShop.id } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#nestle-home-steps {
  background-color: var(--yellow-color);
  padding: 20px 0 40px;

  @include media-breakpoint-up(lg) {
    padding: 20px 0;
  }
}
h2 {
  color: white;
  font-size: 30px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  margin: 0 10px 40px 10px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin: 0 0 40px 0;
  }
}
.ccm-items {
  justify-content: space-between;
  display: flex;
  max-width: 1300px;
  margin: auto;
}
.ccm-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 40px;
}
.nestlesteps-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
  .ccm-number {
    font-weight: 900;
    font-size: 30px;
  }
  .ccm-text {
    font-weight: 500;
    font-size: 18px;
    padding: 0 50px;
    min-height: 55px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  .step1-msg {
    padding-top: 15px;
    font-style: italic;
  }
}
.prev, .next {
  color: transparent !important;
  width: 40px !important;
  height: 40px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  cursor: pointer;
}
.prev {
  background: url("~@/assets/slider-prev-white.svg") no-repeat;
}
.next {
  background: url("~@/assets/slider-next-white.svg") no-repeat;
  background-position-x: right;
}
.ccm-email-block {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0px;
  }

  input {
    border-radius: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: -1px 3px 11px #00000069;
    padding: 0 10px;
    height: 50px;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }
  button {
    background-color: var(--red-color);
    border: none;
    box-shadow: 3px 3px 6px #00000069;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
      img {
        width:25px;
      }
  }
}
.selectdiv {
  position: relative;
  margin-top: 20px;
}
.selectdiv:after {
    content: '';
    background: url("~@/assets/slider-next-red.svg") no-repeat;
    background-size: contain;
    transform: rotate(90deg);
    right: 20px;
    top: 20px;
    height: 20px;
    width: 20px;
    position: absolute;
    pointer-events: none;
}
/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}
.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  width: 100%;
  max-width: 350px;
  height: 50px !important;
  float: right;
  padding: 0.5rem 0.75rem;
  background-color: #ffffff;
  background-image: none;
  border-radius: 10px !important;
  border: none;
  -ms-word-break: normal;
  word-break: normal;
  cursor: pointer;

  &:focus-visible, &:focus, &:active, &:target, &:hover, &:visited {
    outline: none;
    border: none;
  }
}

</style>
