<template>
  <div>
    <HomeNestleTop />
    <div class="container">
      <h1 class="nestleSlogan">{{ $t('home.sloganNestle') }}</h1>
    </div>
    <NestleHomeSteps />
    <div class="mb-5 mt-5">
      <Avantages />
    </div>
  </div>
</template>

<script>
import HomeNestleTop from '@/components/Home/HomeNestleTop.vue'
import Avantages from '@/components/Avantages.vue'
import NestleHomeSteps from '@/components/Home/NestleHomeSteps.vue'

export default {
  name: 'ViewHomeNestle',
  components: {
    HomeNestleTop,
    NestleHomeSteps,
    Avantages
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';
.nestleSlogan {
  font-size: 20px;
  padding: 20px 0;

  @include media-breakpoint-up(lg) {
    font-size: 25px;
  }
}
/* Commment ça marche */
#nestle-home-steps .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  top: calc(50% - 35px);
}
#nestle-home-steps .agile .agile__nav-button--prev {
  left: calc(50% - 190px);
}
#nestle-home-steps .agile .agile__nav-button--next {
  right: calc(50% - 190px);
}

.nestleSloganFooter {
  padding: 2em;
  margin: 2em 0;
  color: #d9023e;
  font-size: 20px;
  border: 1px solid #d9023e;

  p {
    margin: 0;
  }
}
</style>
