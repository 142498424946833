<template>
  <div id="creer-compte">
    <div class="bg-big-text">
      <BigText
        color="white"
        bgcolor="yellow"
        title="MON"
        position="left"
      />
    </div>
    <div class="container section-title">
      <div class="row">
        <h2>CRÉER UN COMPTE</h2>
      </div>
    </div>
    <img class="youpaq-script" src="@/assets/youpaq-script-white.svg">
    <img class="big-icon" src="@/assets/user-big-icon.png">
  </div>
</template>

<script>
import BigText from '@/components/UI/BigText.vue'

export default {
  name: 'CreerCompteHeader',
  components: {
    BigText
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#creer-compte {
  background-color: var(--dark-yellow-color);
  height: 315px;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-bottom: 175px;
  @include media-breakpoint-up(md) {
    align-items: flex-start;
    padding-top: 110px;
    padding-bottom: 0;
    height: 390px;
    z-index: 1;
  }
  .bg-big-text {
    @include media-breakpoint-up(md) {
      z-index: -1;
    }
  }
  .youpaq-script {
    position: absolute;
    max-width: 300px;
    top: 100px;
    left: -25px;

    @include media-breakpoint-up(md) {
      max-width: 625px;
      top: 157px;
      left: -44px;
    }
  }
  .big-icon {
    position: absolute;
    bottom: -65px;
    right: 0;
    margin-right: 10%;
    max-width: 150px;
    max-height: 150px;

    @include media-breakpoint-up(md) {
      bottom: -85px;
      max-width: 200px;
      max-height: 200px;
    }

    @include media-breakpoint-up(lg) {
      bottom: calc(50% - 100px);
    }
  }
  .section-title {
    text-align: left;
    h2 {
      font-size: 30px !important;
      line-height: 40px !important;
      color: white;
      font-weight: 900;
      @include media-breakpoint-up(md) {
        font-size: 35px !important;
      }
      @include media-breakpoint-up(lg) {
        font-size: 40px !important;
      }
    }
    img {
      max-width: 80px;
      @include media-breakpoint-up(md) {
        max-width: 100px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 110px;
      }
    }
  }
}
</style>
