<template>
  <div
    id="home-avantages"
    class="container"
    :class="{ 'flex-column' : isNestleHome}"
  >
    <h2 v-if="isNestleHome" class="row justify-content-center mb-4 mb-lg-5">
      <div class="col-12">{{ $t('howItWorks.title') }}</div>
    </h2>
    <div class="row justify-content-center">
      <div
        id="1"
        class="avantage-item col-12 col-md-6 col-lg-3 pb-5 pb-lg-0 pr-3"
        :class="{ 'col-lg-4 pb-lg-5 order-0' : isNestleHome}"
      >
        <div v-if="!isNestleHome" class="avantage-circle">
          <img src="@/assets/avantage-marche.svg">
        </div>
        <div v-if="isNestleHome" class="avantage-circle">
          <img src="@/assets/avantage-hours.svg">
        </div>
        <div v-if="!isNestleHome" class="avatange-content">
          <h2>{{ $t('home.benefits.title1') }}</h2>
          {{ $t('home.benefits.text1') }}
        </div>
        <div v-if="isNestleHome" class="avatange-content">
          <h2>{{ $t('home.benefits.title1-nestle') }}</h2>
          {{ $t('home.benefits.text1-nestle') }}
        </div>
      </div>

      <div
        id="2"
        class="avantage-item col-12 col-md-6 col-lg-3 pb-5 pb-lg-0"
        :class="{ 'd-none' : isNestleHome}"
      >
        <div v-if="!isNestleHome" class="avantage-circle">
          <img src="@/assets/avantage-livraison.svg">
        </div>
        <div class="avatange-content">
          <h2>{{ $t('home.benefits.title2') }}</h2>
          {{ $t('home.benefits.text2') }}
        </div>
      </div>
      <div
        id="3"
        class="avantage-item col-12 col-md-6 col-lg-3 pb-5 pb-lg-0"
        :class="{ 'col-lg-4 pb-lg-5 order-1' : isNestleHome}"
      >
        <div v-if="!isNestleHome" class="avantage-circle">
          <img src="@/assets/avantage-achete-en-ligne.svg">
        </div>
        <div v-if="isNestleHome" class="avantage-circle">
          <img src="@/assets/avantage-commerce-local.svg">
        </div>
        <div v-if="!isNestleHome" class="avatange-content">
          <h2>{{ $t('home.benefits.title3') }}</h2>
          {{ $t('home.benefits.text3') }}
        </div>
        <div v-if="isNestleHome" class="avatange-content">
          <h2>{{ $t('home.benefits.title3-nestle') }}</h2>
          {{ $t('home.benefits.text3-nestle') }}
        </div>
      </div>
      <div
        id="4"
        class="avantage-item col-12 col-md-6 col-lg-3 pb-5 pb-lg-0"
        :class="{ 'col-lg-4 pb-lg-5 order-2' : isNestleHome}"
      >
        <div v-if="!isNestleHome" class="avantage-circle">
          <img src="@/assets/avantage-commerce-local.svg">
        </div>
        <div v-if="isNestleHome" class="avantage-circle">
          <img src="@/assets/avantage-livraison.svg">
        </div>
        <div v-if="!isNestleHome" class="avatange-content">
          <h2>{{ $t('home.benefits.title4') }}</h2>
          {{ $t('home.benefits.text4') }}
        </div>
        <div v-if="isNestleHome" class="avatange-content">
          <h2>{{ $t('home.benefits.title4-nestle') }}</h2>
          {{ $t('home.benefits.text4-nestle') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avantages',
  computed: {
    isNestleHome() {
      return this.$route.name === 'homenestle'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../assets/styles/youpaq.scss';

#home-avantages {
  display: flex;
  margin: 0px auto 80px;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    margin: 10px auto 120px;
  }
}
h2 {
  color: black;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 10px 10px 10px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin: 0 0 20px 0;
  }
}
.avantage-item {
  max-width: 460px;
}
.avantage-item img {
  max-width: 65px;
}
.avantage-circle {
  background-color: #AC5B9F;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;

  @include media-breakpoint-up(md) {
    margin: 0 auto 20px;
  }
}
.avatange-content {
  h2 {
    font-size: 18px;
    font-weight: 900;
    line-height: 1.5rem;
    padding: 0 40px;
    margin: 0 0 20px 0;
    text-transform: none;
  }
}
.avantage-item[id="1"] .avantage-circle {
  background-color: #AC5B9F;
}
.avantage-item[id="2"] .avantage-circle {
  background-color: #E89E0B;
}
.avantage-item[id="3"] .avantage-circle {
  background-color: #673A8F;
}
.avantage-item[id="4"] .avantage-circle {
  background-color: #27A951;
}
</style>
