<template>
  <div
    id="home-nestle-top"
    class="container-fluid"
  >
    <div class="nestle-home-logos">
      <img src="@/assets/youpaq-logo-home.png" />
      <span>by</span>
      <img src="@/assets/youpaq-nestle-logo-home.png" class="shopLogo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeNestleTop',
  computed: {
    isNestleHome() {
      return this.$route.name === 'homenestle'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#home-nestle-top {
  background: url('~@/assets/banner-nestle-home.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 320px;

  @include media-breakpoint-up(lg) {
    min-height: 300px;
    background: url('~@/assets/banner-nestle-home.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  img {
    width: 80%;
    max-width: 450px;
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      width: 90%;
      padding-top: 100px;
    }
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
    width: 90%;
    color: white;
    max-width: 550px;
    padding: 20px 0;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: 30px;
      width: 70%;
      font-weight: 500;
      padding: 30px 0;
    }
  }

  .nestle-home-logos {
    width: 55%;

    img {
      width: 80%;
      padding-top: 20px;
      @include media-breakpoint-up(md) {
        width: 40%;
      }
    }

    span {
      font-weight: 900;
      color: white;
      font-size: 20px;
      display: block;
      padding: 20px 30px;

      @include media-breakpoint-up(md) {
        display: inline-block;
        padding: 0 30px;
      }
    }

    .shopLogo {
      background: white;
      border-radius: 12px;
      padding: 10px;
    }
  }
}
</style>
