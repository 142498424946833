<template>
  <div id="creer-compte-form" class="pt-5">
    <div v-if="!done" class="container">
      <form @submit.stop.prevent="submit">
        <!-- AUTHENTIFICATION -->
        <div class="row pb-3 pb-md-5">
          <h2>{{ $t('accountCreate.title') }}</h2>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label for="email">{{ $t('accountCreate.email') }}</label>
          </div>
          <div class="col-12 col-md-8">
            <UserEmailField v-model="$v.user.email.$model" :rules="$v.user.email" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3">
            <label for="password">{{ $t('user.password') }}</label>
          </div>
          <div class="pass-input col-12 col-md-4">
            <UserPasswordField v-model="$v.user.password.$model" :rules="$v.user.password" :entropy.sync="passwordEntropy" :placeholder="$t('user.password')" />
          </div>
          <div class="pass-input col-12 col-md-4">
            <UserPasswordField v-model="$v.user.passwordVerification.$model" :rules="$v.user.passwordVerification" disable-entropy :placeholder="$t('accountCreate.passwordConfirm')" />
          </div>
        </div>

        <!-- QUELQUES DÉTAILS -->
        <div class="row pt-3 pt-md-5 pb-3 pb-md-5">
          <h2>{{ $t('accountCreate.details') }}</h2>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label for="date-of-birth">{{ $t('user.birthdate') }}</label>
          </div>
          <div class="col-12 col-md-8">
            <DateField v-model="$v.user.birthdate.$model" :rules="$v.user.birthdate" :max="maxBirthdate" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label for="date-of-birth">{{ $t('user.fullname') }}</label>
          </div>
          <div class="col-4 col-md-2 right-padding">
            <UserCourtesyField v-model="$v.user.courtesyTitle.$model" :rules="$v.user.courtesyTitle" />
          </div>
          <div class="col-8 col-md-3 left-padding">
            <UserNameField v-model="$v.user.firstname.$model" :rules="$v.user.firstname" name="firstname" :placeholder="$t('user.firstname')" />
          </div>
          <div class="offset-4 col-8 offset-md-0 col-md-3 left-padding">
            <UserNameField v-model="$v.user.lastname.$model" :rules="$v.user.lastname" name="lastname" :placeholder="$t('user.lastname')" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label for="address">{{ $t('user.street') }}</label>
          </div>
          <div class="col-12 col-md-8">
            <UserStreetField v-model="$v.user.coordinate.street.$model" :rules="$v.user.coordinate.street" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label for="ville">{{ $t('user.city') }}</label>
          </div>
          <div class="col-12 col-md-8">
            <UserZipField v-model="$v.user.coordinate.zip.$model" :rules="$v.user.coordinate.zip" :city.sync="$v.user.coordinate.city.$model" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label for="pais">{{ $t('user.country') }}</label>
          </div>
          <div class="col-8 col-md-6">
            <UserCountryField v-model="$v.user.coordinate.pays_id.$model" :rules="$v.user.coordinate.pays_id" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label for="address">{{ $t('user.phone') }}</label>
          </div>
          <div class="col-8 col-md-6">
            <UserPhoneField v-model="$v.user.coordinate.phone.$model" :rules="$v.user.coordinate.phone" />
          </div>
        </div>
        <div class="row mt-5 mb-4">
          <CheckboxField
            v-model="user.newsletter.classic"
            class="col-12 offset-md-3 col-md-8"
            :label="$t('user.newsletter')"
          />
        </div>
        <!-- INSCRIPTION -->
        <div class="row pb-3">
          <h2>{{ $t('accountCreate.subscription') }}</h2>
        </div>
        <div class="row mb-4">
          <CheckboxField
            v-model="$v.ok.$model"
            :rules="$v.ok"
            class="col-12 offset-md-3 col-md-8"
            :label="$t('accountCreate.confirm')"
          />
        </div>
        <div class="row mb-4">
          <CheckboxField
            v-model="$v.conditions.$model"
            :rules="$v.conditions"
            class="col-12 offset-md-3 col-md-8"
            :label="$t('accountCreate.cgv')"
            :href="$router.resolve($siteLink($route, 'conditions-generales')).href"
          />
        </div>
        <div class="row mt-5 submit-button">
          <div class="col-12 offset-md-3 col-md-8">
            <label />
            <button type="submit" :disabled="disabled">
              {{ $t('accountCreate.submit') }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="container">
      <div v-marked="$t('accountCreate.done', { email: user.email })" class="alert alert-success" role="alert"></div>
      <SpamMessage />
    </div>
  </div>
</template>

<script>
import DateField from '@/components/UI/DateField'
import CheckboxField from '@/components/UI/CheckboxField'
import UserCourtesyField from '@/components/User/UserCourtesyField'
import UserEmailField from '@/components/User/UserEmailField'
import UserPasswordField from '@/components/User/UserPasswordField'
import UserNameField from '@/components/User/UserNameField'
import UserCountryField from '@/components/User/UserCountryField'
import UserZipField from '@/components/User/UserZipField'
import UserStreetField from '@/components/User/UserStreetField'
import UserPhoneField from '@/components/User/UserPhoneField'
import SpamMessage from '@/components/MenusAndPopin/SpamMessage'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import moment from 'moment'

const maxBirthdate = moment().subtract(18, 'year').toDate()

export default {
  name: 'CreerCompteForm',
  components: {
    DateField,
    CheckboxField,
    UserEmailField,
    UserPasswordField,
    UserNameField,
    UserCourtesyField,
    UserCountryField,
    UserStreetField,
    UserZipField,
    UserPhoneField,
    SpamMessage
  },

  data: () => ({
    maxBirthdate: maxBirthdate,
    loading: false,
    done: false,
    ok: false,
    conditions: false,
    passwordEntropy: 0,
    user: {
      email: null,
      password: null,
      passwordVerification: null,
      birthdate: null,
      courtesyTitle: null,
      firstname: null,
      lastname: null,
      coordinate: {
        street: null,
        city: null,
        zip: null,
        pays_id: null,
        phone: null
      },
      newsletter: {
        classic: false,
        promo: false
      }
    }
  }),

  validations: {
    ok: { required: v => v === true },
    conditions: { required: v => v === true },
    user: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        goodPasswordEntropy(value) {
          if (value === '') {
            return true
          }
          return this.passwordEntropy >= this.$config.passwordEntropy
        }
      },
      passwordVerification: {
        required,
        sameAsPassword: sameAs('password')
      },
      birthdate: {
        required,
        birthdate(v) {
          if (!v) {
            return true
          }
          const date = moment(v)
          return date.isValid() && date.toDate() <= maxBirthdate
        }
      },
      courtesyTitle: { required },
      firstname: { required },
      lastname: { required },
      coordinate: {
        street: { required },
        city: { required },
        zip: { required },
        pays_id: { required },
        phone: { required }
      }
    }
  },

  computed: {
    disabled() {
      return this.loading || this.$v.$invalid
    }
  },

  mounted() {
    const prefs = this.$store.getters['user/preferences']
    this.user.email = prefs.email
  },

  methods: {

    submit() {
      if (this.disabled) {
        return
      }
      this.loading = true
      return this.$store.dispatch('user/create', { user: this.user })
        .then(() => {
          this.done = true
          this.$store.commit('user/preferences', { email: null })
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss">
@import '../../assets/styles/youpaq.scss';

#creer-compte-form {
  background: white;
  margin-bottom: 200px;

  h2 {
    font-size: 30px !important;
    line-height: 40px !important;
    color: var(--yellow-color);
    font-weight: 900;
    margin-top: 20px;
    text-align: left;
    @include media-breakpoint-up(md) {
      font-size: 35px !important;
    }
    @include media-breakpoint-up(lg) {
      font-size: 40px !important;
    }
  }

  form {
    label {
      font-size: 16px;
      font-weight: 700;
      float: left;
      color: var(--yellow-color);

      @include media-breakpoint-up(md) {
        font-size: 18px;
        float: right;
      }
    }
    input:not(.password-input) {
      width: 100%;
      height: 36px;
    }
    input {
      box-sizing: border-box;
      border: none;
      box-shadow: inset 1px 1px 3px #0000009a;
      padding: 10px;
    }
    p {
      font-size: 13px;
      text-align: left;
      padding-top: 5px;
    }
    input[type=checkbox] {
        visibility: hidden;
    }
    .checkbox {
      display: flex;
      align-items: flex-start;
    }
    .rounded-checkbox {
      width: 28px;
      height: 28px;
      min-width: 28px;
      background: #ffffff;
      position: relative;
    }

    .rounded-checkbox label {
      cursor: pointer;
      position: absolute;
      width: 25px;
      height: 25px;

      -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.5);
      -moz-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.5);
      box-shadow: inset 1px 1px 2px rgba(0,0,0,0.5);

      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      left: 0px;
      top: 0px;
    }

    .rounded-checkbox label:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      background: var(--gray-color);

      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      top: 4px;
      left: 4px;
    }

    .rounded-checkbox label:hover::after {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
      filter: alpha(opacity=30);
      opacity: 0.3;
    }

    .rounded-checkbox input[type=checkbox]:checked + label:after {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      opacity: 1;
    }
    .label {
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: var(--yellow-color);
      padding-left: 10px;
    }
    .submit-button {
      display: flex;
      justify-content: center;

      @include media-breakpoint-up(md) {
        justify-content: flex-start;
      }

      button {
        float: left;
        background-color: #BA8DC0;
        border: none;
        padding: 10px 20px;
        border-radius: 10px;
        color: white;
        font-size: 18px;
        font-weight: 700;
        box-shadow: 1px 1px 5px #00000087;

        &[disabled="disabled"] {
          background-color: #ccc;
        }
      }
    }
  }
  form input:focus-visible {
    outline: transparent;
  }
}
</style>
