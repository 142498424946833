<template>
  <div>
    <div v-if="nativeDateInput">
      <input v-model="date" type="date" :max="maxString" class="form-control" :class="{ 'is-invalid': !!invalid }" @blur="blurred = true" />
      <div class="invalid-feedback">
        {{ invalid }}
      </div>
      <label v-if="label">{{ label }}</label>
    </div>
    <div v-else class="row g-2" :class="{ 'is-invalid': !!invalid }">
      <div class="col-sm-12">
        {{ label }}
      </div>
      <div class="col">
        <div class="form-floating mb-3">
          <select v-model="select.day" class="form-select">
            <option v-for="d in days" :key="d" :value="d">{{ d }}</option>
          </select>
          <label>{{ $t('dates.day') }}</label>
        </div>
      </div>
      <div class="col">
        <div class="form-floating mb-3">
          <select v-model="select.month" class="form-select">
            <option v-for="m in months" :key="m.value" :value="m.value">{{ m.text }}</option>
          </select>
          <label>{{ $t('dates.month') }}</label>
        </div>
      </div>
      <div class="col">
        <div class="form-floating mb-3">
          <select v-model="select.year" class="form-select">
            <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
          </select>
          <label>{{ $t('dates.year') }}</label>
        </div>
      </div>
    </div>
    <div class="invalid-feedback">
      {{ invalid }} {{ date }}
    </div>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'
import moment from 'moment'

export default {
  name: 'DateField',
  mixins: [Rules],

  props: {
    value: { type: String },
    label: { type: String },
    max: { type: Date }
  },

  data: () => ({
    nativeDateInput: true,
    years: [],
    months: [],
    days: [],
    select: {
      year: null,
      month: null,
      day: null
    }
  }),

  computed: {
    date: {
      get() {
        if (this.value instanceof Date) {
          return this.value.toISOString().substr(0, 10)
        }
        return (this.value || '').substr(0, 10)
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    maxString() {
      if (this.max instanceof Date) {
        return this.max.toISOString().substr(0, 10)
      }
      return this.max?.substr(0, 10)
    }
  },

  watch: {
    'select.year': 'setDate',
    'select.month': 'setDate',
    'select.day': 'setDate'
  },

  mounted() {
    // check if browser supports native date input
    const test = document.createElement('input')
    test.type = 'date'
    if (test.type === 'text') {
      // date type wasn't set, it means it's not supported
      this.nativeDateInput = false
      this.blurred = true
      this.init()
    }
  },

  methods: {
    init() {
      const startDate = this.max || new Date()
      const year = Number(moment(startDate).format('YYYY'))
      for (let i = year - 100; i <= year; i += 1) {
        this.years.push(i)
      }
      for (let i = 1; i <= 31; i += 1) {
        this.days.push(i)
      }
      const date = moment().locale(this.$config.lang).month(0)
      for (let i = 1; i <= 12; i += 1) {
        this.months.push({
          value: i,
          text: date.format('MMMM')
        })
        date.add(1, 'month')
      }
    },

    setDate() {
      if (!this.select.year || !this.select.month || !this.select.day) {
        return
      }
      const month = String(this.select.month).padStart(2, '0')
      const day = String(this.select.day).padStart(2, '0')

      this.date = `${this.select.year}-${month}-${day}`
    }
  }
}
</script>
