<template>
  <div class="checkbox">
    <div class="rounded-checkbox">
      <input
        :id="id"
        ref="input"
        v-model="check"
        :name="id"
        type="checkbox"
        :class="{ 'is-invalid': !!invalid }"
        @click="forceBlur"
      />
      <label type="checkbox" :for="id"></label>
      <div class="invalid-feedback">
        {{ invalid }}
      </div>
    </div>
    <span v-if="ḧref" class="label" @click="check = !check">{{ label }}</span>
    <a v-else class="label" :href="href" target="_blank">{{ label }}</a>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'

export default {
  name: 'CheckboxField',
  mixins: [Rules],

  props: {
    value: { type: Boolean },
    label: { type: String },
    href: { type: String }
  },

  computed: {
    id() {
      return `check-${this._uid}`
    },

    check: {
      get() {
        return this.value
      },
      set(v) {
        this.$refs.input.checked = v
        this.$emit('input', v)
      }
    }
  },

  methods: {
    forceBlur() {
      this.blurred = true
    }
  }
}
</script>
