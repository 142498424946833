<template>
  <div class="input-group">
    <input
      v-model="password"
      class="form-control password-input"
      :class="{ 'is-invalid': !!invalid }"
      autocapitalize="none"
      :type="visible ? 'text' : 'password'"
      name="password"
      :placeholder="placeholder"
      @blur="onBlur"
    />
    <div class="input-group-text">
      <img :src="image" @click="visible = !visible" />
    </div>
    <div v-if="!disableEntropy" class="progress password-progress">
      <div
        :class="{ [`bg-${color}`]: true }"
        :style="{ width: `${progress}%` }"
        :aria-valuenow="progress"
        class="progress-bar"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div class="invalid-feedback">
      {{ invalid }}
    </div>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
import Rules from '@/mixins/Rules'
import stringEntropy from 'fast-password-entropy'

export default {
  name: 'UserPasswordField',
  mixins: [Rules],

  props: {
    value: { type: String },
    label: { type: String },
    placeholder: { type: String },
    entropy: { type: [String, Number] },
    disableEntropy: { type: Boolean }
  },

  data: () => ({
    visible: false,
    meter: 0,
    progress: 0
  }),

  computed: {
    password: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    image() {
      return this.visible ? '/imgs/eye-off-black.png' : '/imgs/eye-on-black.png'
    },

    minEntropy() {
      return this.$config.passwordEntropy
    },

    goodEntropy() {
      return this.minEntropy * 1.2
    },

    color() {
      if (this.entropy >= this.minEntropy && this.entropy < this.goodEntropy) {
        return 'warning'
      }
      if (this.entropy >= this.goodEntropy) {
        return 'success'
      }
      return 'danger'
    }
  },

  watch: {
    async password(v) {
      if (this.disableEntropy) {
        return
      }
      const entropy = v ? stringEntropy(this.password) : 0
      this.$emit('update:entropy', entropy)
      this.progress = Math.min(1, entropy / this.goodEntropy) * 100
    }
  },

  methods: {
    onBlur() {
      this.blurred = true
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-text img {
  cursor: pointer;
}

.password-progress {
  height: 6px;
  width: 100%;
}
</style>
